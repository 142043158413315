import { createContext, useContext } from "react";

import Tab from "./sbb/Tab";

interface Props {
  children: (language: string) => React.ReactNode;
  "data-cy"?: string;
  hasError?: { de?: boolean; en?: boolean; fr?: boolean; it?: boolean };
}

interface LanguageTabsContextType {
  languageTab: number;
  setLanguageTab: (index: number) => void;
}

export const LanguageTabsContext = createContext<LanguageTabsContextType>({
  languageTab: 0,
  setLanguageTab: () => {},
});

export default function LanguageTabs({ children, hasError, ...props }: Props) {
  const { languageTab, setLanguageTab } = useContext(LanguageTabsContext);

  return (
    <Tab.Group {...props} onChange={setLanguageTab} selectedIndex={languageTab}>
      <Tab hasError={hasError?.de} label="DE">
        {children("De")}
      </Tab>
      <Tab hasError={hasError?.fr} label="FR">
        {children("Fr")}
      </Tab>
      <Tab hasError={hasError?.it} label="IT">
        {children("It")}
      </Tab>
      <Tab hasError={hasError?.en} label="EN">
        {children("En")}
      </Tab>
    </Tab.Group>
  );
}
