import Head from "next/head";
import React, { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Toaster } from "react-hot-toast";
import { SWRConfig } from "swr";

import { LanguageTabsContext } from "@/components/LanguageTabs";
import client, { apiHost } from "@/lib/client";
import I18n from "@/lib/i18n";

import "@/lib/tailwind.css";

import type { RequestDocument, Variables } from "graphql-request";
import type { AppProps } from "next/app";

const fetcher = (params: [RequestDocument, Variables] | RequestDocument) => {
  const [document, variables] = Array.isArray(params) ? params : [params];
  return client.request(document, variables);
};

const HTTPStatusForbidden = 403;

function onError(error: { response?: { status?: number } }) {
  if (error.response?.status === HTTPStatusForbidden) {
    const next = window.location.href;
    window.location.href = `${apiHost}/accounts/microsoft_sbb/login/authenticate/?next=${next}`;
  }
}

function INSAFrontendApp({ Component, pageProps }: AppProps) {
  const [languageTab, setLanguageTab] = useState(0);

  return (
    <DndProvider backend={HTML5Backend}>
      <SWRConfig value={{ fetcher, onError }}>
        <LanguageTabsContext.Provider value={{ languageTab, setLanguageTab }}>
          <I18n>
            <Head>
              <link href="/favicon.ico" rel="shortcut icon" />
              <title>propCMS</title>
            </Head>
            <Toaster />
            <Component {...pageProps} />
          </I18n>
        </LanguageTabsContext.Provider>
      </SWRConfig>
    </DndProvider>
  );
}

export default INSAFrontendApp;
