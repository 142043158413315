import { Tab } from "@headlessui/react";
import { Fragment } from "react";

interface SBBTabProps {
  children?: React.ReactNode;
  className?: string;
  hasError?: boolean;
  label: React.ReactNode;
}

function SBBTab({ children, className }: SBBTabProps) {
  return <Tab.Panel className={className || "p-4"}>{children}</Tab.Panel>;
}

type SBBGroupProps = {
  children: React.ReactElement<SBBTabProps>[];
  defaultIndex?: number;
  onChange?: (index: number) => void;
  selectedIndex?: number;
} & Omit<React.HTMLProps<HTMLDivElement>, "onChange" | "ref">;

SBBTab.Group = function SBBGroup({ children, ...props }: SBBGroupProps) {
  if (children.length === 0) {
    return null;
  }

  return (
    <Tab.Group {...props} as="div">
      <Tab.List>
        {children.map((tab, tabIndex) => (
          <Tab as={Fragment} key={tabIndex}>
            {({ selected }) => (
              <button
                className={`relative -mb-[1px] -mr-[1px] rounded-t-sm border px-4 py-2 outline-none ${
                  selected
                    ? "border-graphite border-b-white"
                    : "hover:border-b-red125 hover:text-red125"
                } ${!selected && tab.props.hasError ? "z-10 border-red" : ""} ${
                  !selected && !tab.props.hasError ? "border-transparent" : ""
                }`}
                type="button"
              >
                {tab.props.label}
              </button>
            )}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels className="border border-graphite">{children}</Tab.Panels>
    </Tab.Group>
  );
};

export default SBBTab;
