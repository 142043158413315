import { GraphQLClient } from "graphql-request";

function getClientOptions(): {
  credentials?: RequestCredentials;
  mode?: RequestMode;
} {
  return process.env.NODE_ENV === "development"
    ? { credentials: "include", mode: "cors" }
    : {};
}

export const apiHost = process.env.NEXT_PUBLIC_API_HOST || "";

const client = new GraphQLClient(`${apiHost}/graphql/`, getClientOptions());

export default client;
